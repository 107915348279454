body {
}
main {
    margin-top: 56px;
}
.copyright {
    border-top: 1px solid lightgray;
    max-width: 800px;
}
a.navbar-brand {
    background: url(../../../wwwroot/images/navbar-brand.png) no-repeat left;
    padding-left: 30px;
    margin-left: 5px !important;
}
div#splash-screen {
    background: url(../../../wwwroot/images/obpdiagram.png) no-repeat top center;
    background-size: contain;
    width: 100%;
    min-width: 256px;
    min-height: 535px;
    display: flex;
}
    div#splash-screen .splashContent {
        display: block;
    }


section.page-section {
    padding: 6rem 0;
}
    section.page-section h3.section-subheading {
        font-size: 1rem;
        font-weight: 400;
        font-style: italic;
        font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        margin-bottom: 4rem;
    }

.btn-xl {
    padding: 1.25rem 2.5rem;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.125rem;
    font-weight: 700;
}

section#contact {
    background-color: #212529;
    background-image: url(../../../wwwroot/images/map-image.png);
    background-repeat: no-repeat;
    background-position: center;
}

    section#contact h3.section-subheading {
        color: #afafaf;
    }

    section#contact form .form-group {
        margin-bottom: 1.5rem;
    }

        section#contact form .form-group input,
        section#contact form .form-group textarea {
            padding: 1.25rem;
        }

            section#contact form .form-group input.form-control {
                height: auto;
            }

    section#contact .section-heading {
        color: #fff;
    }
    section#contact .form-group-textarea {
        height: 100%;
    }
        section#contact .form-group-textarea textarea {
            height: 100%;
            min-height: 10rem;
        }

    section#contact form p.help-block {
        margin: 0;
    }

    section#contact form .form-control:focus {
        border-color: #ffc800;
        box-shadow: none;
    }

    section#contact form ::-webkit-input-placeholder {
        font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 700;
        color: #ced4da;
    }

    section#contact form :-moz-placeholder {
        font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 700;
        color: #ced4da;
    }

    section#contact form ::-moz-placeholder {
        font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 700;
        color: #ced4da;
    }

    section#contact form :-ms-input-placeholder {
        font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 700;
        color: #ced4da;
    }
